// extracted by mini-css-extract-plugin
export var Post = "post-module--Post--2Lz1T";
export var Post__header = "post-module--Post__header--3Rf98";
export var Post__title = "post-module--Post__title--3lENy";
export var Post__date = "post-module--Post__date--3RaI_";
export var Post__img = "post-module--Post__img--3-L5A";
export var Post__edit = "post-module--Post__edit--e3x6-";
export var Post__editIcon = "post-module--Post__editIcon--24GY_";
export var Post__controls = "post-module--Post__controls--3yqmH";
export var Post__content = "post-module--Post__content--2nTgF";
export var Post__tags = "post-module--Post__tags--2e2ll";